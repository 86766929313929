@font-face {
  font-family: 'sonder_script_3regular';
  src: url('./assets/fonts/fenotype_-_sonderscript3.woff2') format('woff2'),
       url('./assets/fonts/fenotype_-_sonderscript3.woff') format('woff');
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: 'aileronthin';
  src: url('./assets/fonts/Aileron-Thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


.App {
  text-align: center;
  /* background-image: url('./assets/images/background.jpg');
  background-repeat: no-repeat; */
  background-color: #FFF5EE;
  background-attachment: fixed;
  background-size: cover;
}

.opaqueWrapper {
  background-color: rgba(255, 255, 255, 0.5)

}

h2 {
  font-family: 'sonder_script_3regular';
  font-size: 4em;
  letter-spacing: 2px;
  padding-bottom: 0.025em;
}

p {
  font-family: 'aileronthin';
  font-size: 1.5em;
  font-weight: 600;
}

.content {
  padding-top: 6em;
  padding-bottom: 2em;
}

.mainBodyWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sideSpacer {
  width: 10%;
}

@media only screen and (max-width : 1024px) {
  .sideSpacer {
    display: none;
  }

  .mainBodyContent {
    width: 100%;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }

  .content {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }

  h2 {
    font-size: 2em;
  }

  p {
    font-size: 1em;
  }
}
