.logo {
  width: 85%;
  margin-left: 4%;
  margin-top: 0.25em;
  margin-bottom: 1em;
}

@media only screen and (max-width : 1024px) {
  .logo {
    margin-top: -1em;
    margin-bottom: 0.25em;
  }
}


