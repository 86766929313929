.locationWrapper {
  padding-bottom: 5em;
}

.mapWrapper {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

@media only screen and (max-width : 1024px) {
  iframe {
    width: 100%;
    height: 400px;
  }
}