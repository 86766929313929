.galleryWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  /* margin-top: 3em;
  margin-bottom: 3em; */
}

.galleryImgWrapper {
  width: 750px;
}

.buttonWrapperLeft {
  padding-right: 2em;
}

.buttonWrapperRight {
  padding-left: 2em;
}

@media only screen and (max-width : 1024px) {

  .galleryImgWrapper {
    width: 300px;
  }

 .galleryImg {
   width: 95%;
 }

 .galleryWrapper {
   height: auto;
 }

 .buttonWrapperLeft {
   padding-right: 0.5em;
 }

 .buttonWrapperRight {
   padding-left: 0.5em;
 }

}