.testimonialContainer {
  height: 15em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.testimonialPane {
  width: 70%
}

.testButtonContainerLeft {
  margin-top: -2em;
  width: 10%;
}

.testButtonContainerRight {
  margin-top: -2em;
  width: 10%;
}

.testIcon {
    cursor: pointer;
    color: rgb(64, 64, 64);
}

.testComponent {
  /* height: auto;
  display: flex;
  flex-direction: column; */
}

/* @media only screen and (max-width : 1024px) {
  .testimonialContainer {
    height: auto;
  }
} */

