.listItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.serviceItem {
    text-align: left;
    width: 30%;
}

.servicePrice {
    text-align: right;
    width: 15%
}

.listText {
    margin: 0;
}

.listSection {
    margin-bottom: 1.5em;
}

.makeItBigger {
    font-size: 1.5em;
}

@media only screen and (max-width : 1024px) {
    .serviceItem {
        width: 70%;
    }

    .servicePrice {
        width: 10%;
    }

    .makeItBigger {
        font-size: 1.0em;
    }
}

