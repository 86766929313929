.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0.75);
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-color: rgba(64, 64, 64, 0.5)
}

.testIcon {
  cursor: pointer;
}

@media only screen and (max-width : 1024px) {
  .menu {
    display: none;
  }
}

.buttonLabel {
  font-family: 'sonder_script_3regular';
  font-size: 2.25em;
  letter-spacing: 1px;
  color: #E75480;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #E75480;
}

a {
  text-decoration: none;
}

.instaIcon {
  margin-right: 0.125em;
  color: rgb(64, 64, 64);
}

.fbIcon {
  margin-left: 0.125em;
  color: rgb(64, 64, 64);
}