input[type='text'], input[type='email'], textarea {
  font-family: 'aileronthin';
  color: black;
  font-weight: 800;
  font-size: 1em;
}

.fieldContainer {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%; 
  display: flex;
  flex-direction: row; 
  justify-content: center;
}

input {
  width: 20%;
  padding: 0.7em;
  border-radius: 5px;
  border-style: inset;
  border-color: grey;
  border-width: 2px;
  background-color: white;
}

textarea {
  width: 20%;
  padding: 0.8em;
  border-radius: 5px;
  border-style: inset;
  border-color: grey;
  border-width: 2px;
  background-color: white;
}

.fieldLabel {
  width: 5%;
  text-align: right;
  margin-right: 2em;
  padding-top: 0.5em;
  font-size: 1em;
  font-family: 'aileronthin';
  font-weight: bold;
}

.formSubmit {
  width: 10%;
  background-color: white;
  border-style: inset;
  font-family: 'aileronthin';
  font-size: 1em;
  color: white;
  background-color: pink;
  font-weight: bold;
}

::placeholder {
  color: pink;
  text-align: start;
  font-family: 'aileronthin';
  font-size: 1em;
  font-style: italic;
}

@media only screen and (max-width : 1024px) {
  ::placeholder {
    color: red;
  }

  .fieldLabel {
    margin: 0;
    width: 20%;
    padding-right: 2.5%
  }

  input {
    width: 60%;
  }

  textarea {
    width: 60%
  }

  .formSubmit {
    width: 50%;
  }
}