@media only screen and (min-width : 1224px) {
  .hamMenu {
    display: none;
  }
}

.hamMenu {
  position: -webkit-sticky;
  position: sticky;
  top: 0; 
  text-align: left;
}

.menuContainer {
  position: absolute;
  width: 32vw;
  background-color: rgba(255, 255, 255, 0.75);
  padding-left: 0.75em;
}

.mobile {
  font-size: 1.5em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.hamburger {
  background-color: rgba(255, 255, 255, 0.75);
  padding: 0.25em;
}

.noRotate {
  margin-top: -5px;
}

.rotated {
  margin-top: -0.1em;
  margin-bottom: -0.05em;
}
